<script setup lang="ts">
import { useLogout } from "@cms/features/Auth/Logout/useLogout";
import { configOverrideDialogVisible, ThemeSetting, useTheme } from "@verbleif/shared";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Verbleif from "./verbleif_v.svg?component";

const { t } = useI18n();
const { themeSetting, theme, isAuto } = useTheme();
const { logout } = useLogout();

const nextTheme = computed(() => {
  switch (theme.value) {
    case ThemeSetting.DARK:
      return ThemeSetting.LIGHT;
    case ThemeSetting.LIGHT:
      return ThemeSetting.DARK;
  }

  return ThemeSetting.DARK;
});

function switchTheme() {
  themeSetting.value = nextTheme.value;
}
</script>

<template>
  <div class="navbar-container">
    <div class="logo" @click.shift="configOverrideDialogVisible = true">
      <Verbleif />
    </div>
    <div class="items">
      <div class="navbar-links">
        <router-link
          v-slot="{ navigate, isActive }"
          custom
          :to="{ name: 'client-overview' }"
        >
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'buildings']" class="icon" />
            <div class="title">
              Customers
            </div>
          </div>
        </router-link>
        <router-link
          v-slot="{ navigate, isActive }"
          custom
          :to="{ name: 'global-users-overview' }"
        >
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'users']" class="icon" />
            <div class="title">
              Users
            </div>
          </div>
        </router-link>
        <router-link
          v-slot="{ navigate, isActive }"
          custom
          :to="{ name: 'global-permission-template-groups-overview' }"
        >
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'lock']" class="icon" />
            <div class="title">
              Rollen
            </div>
          </div>
        </router-link>
        <router-link
          v-slot="{ navigate, isActive }"
          custom
          :to="{ name: 'global-appstore-overview' }"
        >
          <div
            class="item"
            :class="[{ 'is-active': isActive }]"
            @click="navigate"
          >
            <FontAwesomeIcon :icon="['fad', 'link']" class="icon" />
            <div class="title">
              App Store
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="nav-item-small theme-switch" @click="switchTheme">
      <FontAwesomeIcon class="icon" icon="adjust" />
      <div class="title">
        <VTooltip :content="t('navbar.theme-auto')">
          <template #reference>
            <font-awesome-icon v-if="isAuto" class="next-icon" :icon="['far', 'repeat']" />
          </template>
        </VTooltip>
        {{ t(`navbar.theme-${nextTheme}`) }}
      </div>
    </div>
    <div class="logout" @click="() => logout()">
      {{ t("base.logout") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navbar-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  width: 90px;
  height: 100vh;
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  padding: 25px 0;

  .logo {
    margin-bottom: 25px;

    &::v-deep(.v) {
      fill: var(--text-invert);
    }
  }

  .stop-impersonation {
    font-weight: bold;

    .icon {
      font-size: 25px;
      transition: none;
    }

    &:hover {
      color: var(--secondary);
    }
  }

  .items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .navbar-links {
      margin-top: 25px;
    }

    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      width: 100%;
      padding: 20px 0;
      transition: ease all 100ms;
      text-decoration: none;
      gap: 15px;

      &:hover,
      &.router-link-exact-active,
      &.is-active {
        background-color: var(--primary-darker);
      }

      &.router-link-exact-active {
        .title {
          font-weight: bold;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        color: var(--text-invert);
        font-size: 30px;
      }

      .title {
        text-align: center;
        word-break: break-all;
        max-width: 85%;
        color: var(--text-invert);
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .nav-item-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-invert);
    width: 100%;
    transition: 100ms;
    text-align: center;
    cursor: pointer;
    gap: 10px;
    padding: 15px 0;

    &:hover {
      transition: 100ms;
      background-color: var(--primary-darker);
    }

    .title {
      font-size: 12px;
    }
  }

  .notifications {
    .badge-container {
      position: relative;

      .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 0 2px;
        height: 16px;
        min-width: 16px;
        text-align: center;
        vertical-align: center;
        line-height: 17px;
        font-weight: bold;
        background-color: theme("colors.danger.600");
        font-size: 10px;
        color: var(--text-invert);
        border-radius: 20px;
        z-index: 10;
      }
    }
  }

  .theme-switch {
    color: var(--text-invert);

    .icon {
      transition: transform 100ms ease-in-out;
    }

    .title {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  .update-available {
    font-size: 20px;
    position: relative;
    cursor: pointer;
    -webkit-animation: blink 1s infinite;
    -moz-animation: blink 1s infinite;
    -o-animation: blink 1s infinite;
    animation: blink 1s infinite;
  }

  @-webkit-keyframes blink {
    0%,
    49% {
      color: var(--text-invert);
    }
    50%,
    100% {
      color: var(--primary);
    }
  }

  .logout {
    margin: 10px 0;
    color: var(--text-invert);
    text-decoration: underline;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      color: #e0e0e0;
    }
  }

  .version {
    color: var(--text-invert);
    font-size: 12px;
  }
}

@media only screen and (max-height: 700px) {
  .navbar-container {
    .items {
      .notification-item {
        .title {
          display: none;
        }
      }

      .item {
        padding: 15px 0;

        .icon {
          font-size: 25px;
        }
      }
    }
  }
}
</style>
