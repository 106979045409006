import GlobalAppstoreContainer from "@cms/features/Appstore/GlobalAppstoreContainer.vue";

export const GlobalAppstoreOverviewRoutes = [
  {
    path: "/app-store",
    name: "global-appstore",
    component: GlobalAppstoreContainer,
    redirect: { name: "global-appstore-overview" },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "global-appstore-overview" },
      },
      {
        path: "",
        name: "global-appstore-overview",
        component: () => import("./GlobalAppstoreOverview.vue"),
      },
    ],
  },
];
