import ClientsContainer from "@cms/features/Clients/ClientsContainer.vue";

export const ClientRoutes = [
  {
    path: "/clients",

    name: "clients",
    component: ClientsContainer,
    redirect: { name: "client-overview" },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "client-overview" },
      },
      {
        path: "",
        name: "client-overview",
        component: () => import("./ClientsOverview.vue"),
      },
    ],
  },
];
