import GlobalUsersContainer from "@cms/features/Users/GlobalUsersContainer.vue";

export const GlobalUsersOverviewRoutes = [
  {
    path: "/users",
    name: "global-users",
    component: GlobalUsersContainer,
    redirect: { name: "global-users-overview" },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "global-users-overview" },
      },
      {
        path: "",
        name: "global-users-overview",
        component: () => import("./GlobalUsersOverview.vue"),
      },
    ],
  },
];
