import router from "@cms/core/plugin/router";
import { useAuthStore, useConfigStore, useLocationStore, useSharedStore } from "@verbleif/shared";

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function useLogout() {
  const authStore = useAuthStore();
  const { config } = useConfigStore();
  const locationStore = useLocationStore();
  const sharedStore = useSharedStore();

  async function logout({
    redirectToLogin = true,
    keepReturnUrl = false,
  }: {
    redirectToLogin?: boolean
    keepReturnUrl?: boolean
  } = {}) {
    if (!config.value) {
      throw new Error("Config not set");
    }

    await router.push({ name: "logout" });

    await delay(1000);

    if (redirectToLogin) {
      await Promise.all([
        authStore.reset(keepReturnUrl),
        locationStore.reset(),
        sharedStore.reset(),
      ]);

      if (import.meta.env.VITE_SKIP_OAUTH === "true") {
        await router.push({ name: "login" });
      } else {
        console.log("[Logout] Redirecting to login", config.value.loginUrl);
        window.location.replace(`${config.value.loginUrl}/logout`);
      }
    }
  }

  return { logout };
}
