import GlobalPermissionTemplateGroupsContainer from "@cms/features/PermissionTemplateGroups/GlobalPermissionTemplateGroupsContainer.vue";

export const GlobalPermissionTemplateGroupsOverviewRoutes = [
  {
    path: "/permission-template-groups",
    name: "permission-template-groups",
    component: GlobalPermissionTemplateGroupsContainer,
    redirect: { name: "global-permission-template-groups-overview" },
    children: [
      {
        path: "/:catchAll(.*)",
        redirect: { name: "global-permission-template-groups-overview" },
      },
      {
        path: "",
        name: "global-permission-template-groups-overview",
        component: () => import("./GlobalPermissionTemplateGroupsOverview.vue"),
      },
    ],
  },
];
