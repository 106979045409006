<script>
import { useI18n } from "vue-i18n";

export default {
  name: "GlobalPermissionTemplateGroupsContainer",
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<template>
  <div class="w-full h-full flex overflow-hidden">
    <div class="w-full pr-5 pl-5 pt-4 overflow-hidden">
      <router-view />
    </div>
  </div>
</template>
