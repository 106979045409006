import { GlobalAppstoreOverviewRoutes } from "@cms/features/Appstore/GlobalAppstoreOverviewRoutes";
import AuthRoutes from "@cms/features/Auth/AuthRoutes";
import { ClientRoutes } from "@cms/features/Clients/ClientsRoutes";
import { GlobalPermissionTemplateGroupsOverviewRoutes } from "@cms/features/PermissionTemplateGroups/GlobalPermissionTemplateGroupsRoutes";
import { GlobalUsersOverviewRoutes } from "@cms/features/Users/GlobalUsersOverviewRoutes";
import { useAuthStore } from "@verbleif/shared";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  ...ClientRoutes,
  ...GlobalUsersOverviewRoutes,
  ...GlobalPermissionTemplateGroupsOverviewRoutes,
  ...GlobalAppstoreOverviewRoutes,
  ...AuthRoutes,
];
const { returnUrl, hasToken, isRemembered } = useAuthStore();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});
export default router;

router.beforeEach((to, _, next) => {
  if (to.meta.skipAuthChecks) {
    return next();
  }

  const isUnauthenticated = !hasToken.value && !isRemembered.value;
  // const isAuthenticated = hasToken.value || isRemembered.value;

  if (isUnauthenticated && !to.meta.public) {
    returnUrl.value = to.fullPath;
    return next({ name: "login" });
  }

  if (to.meta.public) {
    return next();
  }

  // if (isAuthenticated && !isAdmin.value) {
  //   return next({ name: "404" });
  // }

  return next();
});
