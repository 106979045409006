import app from "@cms/core/app";
import { httpClientIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/vue";
import { useConfigStore } from "@verbleif/shared";
import router from "./router";

if (import.meta.env.PROD) {
  const configStore = useConfigStore();
  Sentry.init({
    app,
    dsn: "https://abbc36bdc72342ec8e147b91e97edc62@sentry.vitrion.dev/36",
    environment: configStore.config.value?.appEnv,
    integrations: [
      httpClientIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.vueIntegration({
        tracingOptions: {
          trackComponents: true,
        },
      }),
    ],
    sendDefaultPii: true,
    tracePropagationTargets: [
      "cms.verbleif.dev",
      "cms.verbleif.com",
      "cms.staging.verbleif.com",
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.2,
  });
}
